import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import { AddressAutofill } from "@mapbox/search-js-react";
import { makeRequest2, makeRequest3 } from "../../../utils/makeRequest";
import { toastify } from "../../../utils/toast";
import {
  addCompanyURL,
  confirmCompanyNameURL,
  confirmUserEmailURL,
} from "../../../utils/urls";
import { updateSpinner } from "../../../features/authentication/authenticationReducer";
import Layout from "../component/layout";
import { useNavigate, Link } from "react-router-dom";
import { countries } from "../../../utils/data";

function AddCompany() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [steps, setStep] = useState(['User Type', 'User Information', 'Company Information', 'KYC', 'Facility', 'Finish'])
	const [currentStep, setCurrentStep] = useState(steps[0])
	const [progressBar, setProgressBar] = useState(300)
	const [useType, setUserType] = useState('Individual');
	const [ArrayDivisions, setArrayDivisions] = useState([])
	const [firstName, setFirstName] = useState('Evans');
	const [lastName, setLastName] = useState('Mburu')
	const [email, setEmail] = useState('evans.njogu01@gmail.com');
	const [phoneNumber, setPhoneNumber] = useState('0799010210');
	const [companyName, setCompanyName] = useState('TEST');
	const [companyEmail, setCompanyEmail] = useState('test@gmail.com');
	const [companyAddress, setCompanyAddress] = useState('Nairobi');
	const [companyCountry, setCompanyCountry] = useState('Kenya');
	const [companyCity, setCompanyCity] = useState('Nairobi');
	const [companyRegistrationNumber, setCompanyRegistrationNumber] = useState('12345');
	const [companyPinNumber, setCompanyPinNumber] = useState('');
	const [facilityName, setFacilityName] = useState('');
	const [facilityLocation, setFacilityLocation] = useState('')
	const [subDivision, setSubDivision] = useState('Block');
	const [idNumber, setIDNumber] = useState('12345')
	const [numberOfDivision, setNumberofDivision] = useState(0)
	const [companyTaxNumber, setCompanyTaxNumber] = useState('')
	const [existingUser, visibleExistingUser] = useState(false)
	const [divisionArray, setDivisionArray] = useState([])
	const [taxDocument, setTaxDocument] = useState(null);
	const [companyCertificateDocument, setCompanyCertificateDocument] = useState(null);
	const [IdPassportDocument, setIdPassportDocument] = useState(null);
	const [userExist, setUserExist] = useState({})

  const onTaxDocumentChange = (e) => {
    setTaxDocument(e.target.files[0]);
  };

  const onCompanyCertificateDocumentChange = (e) => {
    setCompanyCertificateDocument(e.target.files[0]);
  };
  const onIDPassportChange = (e) => {
    setIdPassportDocument(e.target.files[0]);
  };

  const handleNextStep = async () => {
    if (currentStep === "User Type" && useType === "Individual") {
      setCurrentStep("User Information");
      setProgressBar(600);
    } else if (currentStep === "User Type" && useType === "Company") {
      setCurrentStep("User Information");
      setProgressBar(500);
    } else if (currentStep === "User Information" && useType === "Company") {
      if (firstName === "") {
        toastify("First Name is required", "error");
      } else if (lastName === "") {
        toastify("Last Name is required", "error");
      } else if (email === "") {
        toastify("Email is required", "error");
      } else if (phoneNumber === "") {
        toastify("Phone Number is required", "error");
      } else if (idNumber === "") {
        toastify("ID Number is required", "error");
      } else {
        const response = await makeRequest2(
          confirmUserEmailURL + "/" + email,
          "GET"
        );

        if (response.success) {
          setUserExist(response.data.userExist);
          visibleExistingUser(true);
        } else {
          setCurrentStep("Company Information");
          setProgressBar(800);
        }
      }
    } else if (currentStep === "User Information" && useType === "Individual") {
      if (firstName === "") {
        toastify("First Name is required", "error");
      } else if (lastName === "") {
        toastify("Last Name is required", "error");
      } else if (email === "") {
        toastify("Email is required", "error");
      } else if (phoneNumber === "") {
        toastify("Phone Number is required", "error");
      } else if (idNumber === "") {
        toastify("ID Number is required", "error");
      } else {
        const response = await makeRequest2(
          confirmUserEmailURL + "/" + email,
          "GET"
        );

				if (response.success) {
					setUserExist(response.data.userExist)
					visibleExistingUser(true)

				}
				else {
					setCurrentStep('KYC')
					setProgressBar(900)
				}

			}
		}
		else if (currentStep === 'Company Information') {
			if (companyName === '') {
				toastify('Company Name is required', 'error')
			}
			else if (companyEmail === '') {
				toastify('Company Email is required', 'error')
			}
			else if (companyAddress === '') {
				toastify('Company Address is required', 'error')
			}
			else if (companyCountry === '') {
				toastify('Country required', 'error')
			}
			else if (companyCity === '') {
				toastify('Country required', 'error')
			}
			else if (companyRegistrationNumber === '') {
				toastify('Company Registration Number is required', 'error')
			}
			else if (companyTaxNumber === '') {
				toastify('Company Tax Number is required', 'error')
			}

			else if (companyCity === '') {
				toastify('Company City is required', 'error')
			}
			else {
				setCurrentStep('KYC')
				setProgressBar(1100)
			}

		}
		else if (currentStep === 'KYC' && useType === 'Company') {
			if (taxDocument === null) {
				toastify('Tax Document is required', 'error')
			}
			else if (companyCertificateDocument === null) {
				toastify('Company Certificate is required', 'error')
			}
			else if (companyPinNumber === null) {
				toastify('Company Pin Number is required', 'error')
			}


			else {
				setCurrentStep('Facility')
				setProgressBar(1300)
			}
		}
		else if (currentStep === 'KYC' && useType === 'Individual') {
			if (IdPassportDocument === null) {
				toastify('ID / Passport is required', 'error')
			}
			else {
				setCurrentStep('Facility')
				setProgressBar(1200)
			}

		}
		else if (currentStep === 'Facility' && useType === 'Company') {
			if (facilityName === '') {
				toastify('Facility Name is required', 'error')
			}
			else if (facilityLocation === '') {
				toastify('Facility Location is required', 'error')
			}
			else if (subDivision === '') {
				toastify('Sub Division is required', 'error')
			}
			else if (divisionArray.length === 0) {
				toastify('Divisions should be greater than 0', 'error')
			}
			else {
				setCurrentStep('Finish')
				setProgressBar('100%')
			}
		}
		else if (currentStep === 'Facility' && useType === 'Individual') {
			if (facilityName === '') {
				toastify('Facility Name is required', 'error')
			}
			else if (facilityLocation === '') {
				toastify('Facility Location is required', 'error')
			}
			else if (subDivision === '') {
				toastify('Sub Division is required', 'error')
			}
			else if (divisionArray.length === 0) {
				toastify('Divisions should be greater than 0', 'error')
			}
			else {
				setCurrentStep('Finish')
				setProgressBar('100%')
			}
		}

	}
	const handleBackToPrevious = async () => {
		if (currentStep === 'User Information' && useType === 'Company') {
			setCurrentStep('User Type')
			setProgressBar(280)
		}
		else if (currentStep === 'User Information' && useType === 'Individual') {
			setCurrentStep('User Type')
			setProgressBar(300)
		}
		else if (currentStep === 'Company Information') {
			setCurrentStep('User Information')
			setProgressBar(500)
		}
		else if (currentStep === 'KYC' && useType === 'Company') {
			setCurrentStep('Company Information')
			setProgressBar(800)
		}
		else if (currentStep === 'KYC' && useType === 'Individual') {
			setCurrentStep('User Information')
			setProgressBar(650)
		}
		else if (currentStep === 'Facility' && useType === 'Company') {
			setCurrentStep('KYC')
			setProgressBar(1050)
		}
		else if (currentStep === 'Facility' && useType === 'Individual') {
			setCurrentStep('KYC')
			setProgressBar(900)
		}
		else if (currentStep === 'Finish' && useType === 'Individual') {
			setCurrentStep('Facility')
			setProgressBar(1200)
		}
		else if (currentStep === 'Finish' && useType === 'Company') {
			setCurrentStep('Facility')
			setProgressBar('100%')
		}

	}
	const handleFinish = async (e) => {
		try {
			dispatch(updateSpinner(true));
			e.preventDefault();
			const formData = new FormData();
			if (taxDocument) {
				formData.append('taxDocument', taxDocument);
			}
			if (companyCertificateDocument) {
				formData.append('companyCertificateDocument', companyCertificateDocument);
			}
			if (IdPassportDocument) {
				formData.append('IdPassportDocument', IdPassportDocument);
			}
			let userInformation = {
				userType: useType,
				firstName: firstName,
				lastName: lastName,
				phoneNumber,
				email: email,
				idNumber: idNumber
			}
			let facilityInformation = {
				facilityName,
				facilityLocation,
				subDivision,
				divisionArray
			}
			const body = useType === 'Individual' ? {
				...userInformation,
				...facilityInformation,
				//formData

			} :
				{
					...userInformation,
					companyName,
					companyAddress,
					companyCountry,
					companyCity,
					companyRegistrationNumber,
					companyEmail,
					companyTaxNumber,
					companyPinNumber,
					...facilityInformation,
				}

			const response = await makeRequest3(addCompanyURL, 'POST', body)
			if (response.success) {
				toastify('Company has been added succesfully.', 'success')
				dispatch(updateSpinner(false))
				navigate('/core/companies')
				// setStep('User Type')
				// setProgressBar(300)
				// setCurrentStep('User Type')
				// setUserType('Individual');
				// setArrayDivisions([])
				// setFirstName('');
				// setLastName('')
				// setEmail('');
				// setPhoneNumber('');
				// setCompanyName('');
				// setCompanyEmail('');
				// setCompanyAddress('');
				// setCompanyCountry('');
				// setCompanyCity('');
				// setCompanyRegistrationNumber('');
				// setFacilityName('');
				// setFacilityLocation('')
				// setSubDivision('Block');
				// setIDNumber('')
				// setDivisionArray([])
				// setTaxDocument(null);
				// setCompanyCertificateDocument(null);
				// setIdPassportDocument(null);

			}
			else {

				throw new Error(response.error)

			}

		}
		catch (err) {
			toastify(err.message, 'error')
			dispatch(updateSpinner(false));
		}
	}

  const CheckUserEmail = async (email) => {
    try {
      const response = await makeRequest2(
        confirmUserEmailURL + "/" + email,
        "GET"
      );
      if (response.success) {
        setUserExist(response.data.userExist);
        toastify("User Email exists", "error");
      }
    } catch (e) {
      console.log(e.message);
    }
  };
  const CheckCompanyName = async (company) => {
    try {
      const response = await makeRequest2(
        confirmCompanyNameURL + "/" + company,
        "GET"
      );
      if (response.success) {
        toastify("Company Name exists", "error");
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/core/dashboard"}>Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/core/companies"}>Business Partners</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  Add Business Partner
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <Link to={"/core/companies"}>
                <span>
                  <i className="ti ti-arrow-narrow-left"></i> Back
                </span>
              </Link>
            </div>
          </div>
          <div
            id="basicwizard"
            className="form-wizard row justify-content-center"
          >
            <div className="col-12">
              <div className="card">
                <div className="card-body p-3">
                  <ul className="nav nav-pills nav-justified">
                    <li
                      className="nav-item"
                      data-target-form="#contactDetailForm"
                    >
                      <a
                        href="#contactDetail"
                        className={`nav-link ${
                          currentStep === "User Type" ? `active` : ``
                        }`}
                      >
                        <i className="ph-duotone ph-user-circle"></i>
                        <span className="d-none d-sm-inline">User Type</span>
                      </a>
                    </li>

                    <li
                      className="nav-item"
                      data-target-form="#contactDetailForm"
                    >
                      <a
                        href="#contactDetail"
                        className={`nav-link ${
                          currentStep === "User Information" ? `active` : ``
                        }`}
                      >
                        <i className="ph-duotone ph-user-circle"></i>
                        <span className="d-none d-sm-inline">
                          User Information
                        </span>
                      </a>
                    </li>
                    {useType === "Company" && (
                      <li
                        className="nav-item"
                        data-target-form="#jobDetailForm"
                      >
                        <a
                          href="#jobDetail"
                          className={`nav-link icon-btn ${
                            currentStep === "Company Information"
                              ? `active`
                              : ``
                          }`}
                        >
                          <i className="ph-duotone ph-buildings"></i>
                          <span className="d-none d-sm-inline">
                            Company Information
                          </span>
                        </a>
                      </li>
                    )}

                    <li
                      className="nav-item"
                      data-target-form="#educationDetailForm"
                    >
                      <a
                        href="#educationDetail"
                        className={`nav-link icon-btn ${
                          currentStep === "KYC" ? `active` : ``
                        }`}
                      >
                        <i className="ph-duotone ph-file-text"></i>
                        <span className="d-none d-sm-inline">KYC</span>
                      </a>
                    </li>
                    <li
                      className="nav-item"
                      data-target-form="#educationDetailForm"
                    >
                      <a
                        href="#educationDetail"
                        className={`nav-link icon-btn ${
                          currentStep === "Facility" ? `active` : ``
                        }`}
                      >
                        <i className="ph-duotone ph-warehouse"></i>
                        <span className="d-none d-sm-inline">Facility</span>
                      </a>
                    </li>

                    <li className="nav-item">
                      <a
                        href="#finish"
                        className={`nav-link icon-btn ${
                          currentStep === "Finish" ? `active` : ``
                        }`}
                      >
                        <i className="ph-duotone ph-check-circle"></i>
                        <span className="d-none d-sm-inline">Finish</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="tab-content">
                    <div
                      id="bar"
                      className="progress mb-3"
                      style={{ height: 7, width: progressBar }}
                    >
                      <div className="bar progress-bar progress-bar-striped progress-bar-animated bg-success"></div>
                    </div>
                    <div
                      className={`tab-pane  ${
                        currentStep === "User Type" && `show active`
                      }`}
                      id="contactDetail"
                    >
                      <form id="contactForm" method="get" action="#">
                        <div className="row mt-4">
                          <div className="col">
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="mb-3">
                                  <label className="form-label">Select</label>
                                  <select
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => {
                                      setUserType(e.target.value);
                                    }}
                                  >
                                    <option value="Individual">
                                      Individual
                                    </option>
                                    <option value="Company">Company</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div
                      className={`tab-pane  ${
                        currentStep === "User Information" && `show active`
                      }`}
                      id="contactDetail"
                    >
                      <form id="contactForm" method="get" action="#">
                        <div className="row mt-4">
                          <div className="col">
                            <div className="row">
                              <div className="col-sm-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    First Name{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter First Name"
                                    value={firstName}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      setFirstName(value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Last Name{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Last Name"
                                    value={lastName}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      setLastName(value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Email Address{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter Email Address"
                                    value={email}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      setEmail(value);
                                      CheckUserEmail(value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Phone Number{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="tel"
                                    className="form-control"
                                    placeholder="Enter Phone Number"
                                    value={phoneNumber}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      setPhoneNumber(value);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="mb-3">
                                  <label className="form-label">
                                    ID Number{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter Id Number"
                                    value={idNumber}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      setIDNumber(value);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div
                      className={`tab-pane  ${
                        currentStep === "Company Information" && `show active`
                      }`}
                      id="jobDetail"
                    >
                      <form id="jobForm" method="post" action="#">
                        <div className="row mt-4">
                          <div className="col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Company Name{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Company Name"
                                value={companyName}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  setCompanyName(value);
                                  CheckCompanyName(value);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Company Email{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Enter Email"
                                value={companyEmail}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  setCompanyEmail(value);
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-sm-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Address <span style={{ color: "red" }}>*</span>
                              </label>
                              <AddressAutofill
                                accessToken={
                                  "pk.eyJ1IjoiZXZhbnMtbmpvZ3UiLCJhIjoiY2s3dzdrM3V5MDFmNDNkcGRqZDdzcTR1eCJ9.pk-MT1CGAs8FutVkiyWs1g"
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Address"
                                  value={companyAddress}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    setCompanyAddress(value);
                                  }}
                                />
                              </AddressAutofill>
                            </div>
                          </div>

													<div className="col-sm-6">
														<div className="mb-3">
															<label className="form-label">Country <span style={{ color: 'red' }}>*</span></label>
															<select className="form-select" value={companyCountry} onChange={(e) => {
																let value = e.target.value
																setCompanyCountry(value)
															}}>
																<option value="">Select Contry</option>
																{
																	countries.map((country) => {
																		return <option value={country}>{country}</option>
																	})
																}
															</select>
														</div>
													</div>
													<div className="col-sm-6">
														<div className="mb-3">
															<label className="form-label">City <span style={{ color: 'red' }}>*</span></label>
															<input type="text" className="form-control" placeholder="City Name " value={companyCity} onChange={(e) => {
																let value = e.target.value
																setCompanyCity(value)

															}} />
														</div>
													</div>
													<div className="col-sm-6">
														<div className="mb-3">
															<label className="form-label">Company Registration Number <span style={{ color: 'red' }}>*</span></label>
															<input type="text" className="form-control" placeholder="Company Registration Number " value={companyRegistrationNumber} onChange={(e) => {
																let value = e.target.value
																setCompanyRegistrationNumber(value)

															}} />
														</div>
													</div>
													<div className="col-sm-6">
														<div className="mb-3">
															<label className="form-label">Company Tax Number <span style={{ color: 'red' }}>*</span></label>
															<input type="text" className="form-control" placeholder="Company Tax Number " value={companyTaxNumber} onChange={(e) => {
																let value = e.target.value
																setCompanyTaxNumber(value)

															}} />
														</div>
													</div>
												</div>
											</form>
										</div>
										<div className={`tab-pane  ${currentStep === 'KYC' && `show active`}`} id="educationDetail">
											<form id="educationForm" method="post" action="#">

												<div className="row mt-4">
													{
														useType === 'Company' &&
														<>
															<div className="col-md-12">
																<div className="mb-3">
																	<label className="form-label" for="schoolName">Tax Pin Certificate <span style={{ color: 'red' }}>*</span></label>
																	<input type="file" className="form-control" placeholder="Enter tax number" accept=".jpg, .png,.pdf,.jpeg" onChange={onTaxDocumentChange} />
																</div>
															</div>
															<div className="col-md-12">
																<div className="mb-3">
																	<label className="form-label" for="schoolLocation">Company Certificate <span style={{ color: 'red' }}>*</span></label>
																	<input type="file" className="form-control" placeholder="Enter company certificate number" accept=".jpg, .png,.pdf,.jpeg" onChange={onCompanyCertificateDocumentChange} />
																</div>
															</div>
															<div className="mb-3">
																<label className="form-label">PIN Number <span style={{ color: 'red' }}>*</span></label>
																<input type="text" className="form-control" placeholder="Company PIN Number" value={companyPinNumber} onChange={(e) => setCompanyPinNumber(e.target.value)}/>
															</div>
														</>
													}

                          {useType === "Individual" && (
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  for="schoolLocation"
                                >
                                  ID / Passport{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  placeholder="ID / Passport"
                                  accept=".jpg, .png,.pdf,.jpeg"
                                  onChange={onIDPassportChange}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                    <div
                      className={`tab-pane  ${
                        currentStep === "Facility" && `show active`
                      }`}
                      id="finish"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-12">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  for="schoolLocation"
                                >
                                  Facility Name{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter facility name"
                                  value={facilityName}
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    setFacilityName(value);
                                  }}
                                />
                              </div>
                            </div>
                            {/* <div className="col-lg-4">
															<div className="mb-3">
																<label className="form-label" for="schoolLocation">Land Reference Number <span style={{ color: 'red' }}>*</span></label>
																<input type="text" className="form-control" placeholder="Enter Land Reference Number" value={landReferenceNumber} onChange={(e) => {
																	let value = e.target.value
																	setLandReferenceNumber(value)
																}} />
															</div>
														</div> */}
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <label
                                  className="form-label"
                                  htmlFor="schoolLocation"
                                >
                                  Facility Location{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <AddressAutofill
                                  accessToken={
                                    "pk.eyJ1IjoiZXZhbnMtbmpvZ3UiLCJhIjoiY2s3dzdrM3V5MDFmNDNkcGRqZDdzcTR1eCJ9.pk-MT1CGAs8FutVkiyWs1g"
                                  }
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Click to get location or enter manually"
                                    value={facilityLocation}
                                    onChange={(e) => {
                                      setFacilityLocation(e.target.value);
                                    }}
                                  />
                                </AddressAutofill>
                              </div>
                            </div>

														<div className="col-lg-6">
															<div className="mb-3">
																<label className="form-label" for="schoolLocation">Sub Division Type <span style={{ color: 'red' }}>*</span></label>
																<select type="text" className="form-control" placeholder="Enter Subdivision Type" value={subDivision} onChange={(e) => {
																	let value = e.target.value;
																	setSubDivision(value);
																	setArrayDivisions([])
																	setDivisionArray([])
																	setNumberofDivision(0)

																}}>
																	<option value="">-- Select --</option>
																	<option value="Phase">Phases</option>
																	<option value="Zone">Zones</option>
																	<option value="Block">Blocks</option>
																	<option value="Court">Courts</option>
																	<option value="Cluster">Clusters</option>
																</select>
															</div>

														</div>
														<div className="col-lg-6">
															<div className="mb-3">
																<label className="form-label" for="schoolLocation">Number of {subDivision}(s) <span style={{ color: 'red' }}>*</span></label>
																<input type="number" value={numberOfDivision} className="form-control" placeholder={`Enter number of ` + subDivision} onChange={(e) => {
																	let value = e.target.value;
																	const array = [];
																	let d = []
																	for (let i = 0; i < value; i++) {
																		array.push(i + 1);
																		d.push({
																			divisionName: subDivision + ' ' + (i + 1),
																			NoOfFloors: 0
																		})
																	}
																	setNumberofDivision(value)
																	setArrayDivisions(array)
																	setDivisionArray(d)
																}} />
															</div>
														</div>
														{
															ArrayDivisions.map((item, index) => {
																return < >
																	<div className="col-lg-6">
																		<div className="mb-3">
																			<label className="form-label" for="schoolLocation">{subDivision} Name</label>
																			<input type="text" className="form-control" placeholder={divisionArray[index].divisionName} onChange={(e) => {
																				let array = divisionArray
																				array[index].divisionName = e.target.value
																				setDivisionArray(array)
																			}} />
																		</div>
																	</div>
																	<div className="col-lg-6">
																		<div className="mb-3">
																			<label className="form-label" for="schoolLocation">No of {subDivision === 'Block' ? 'Floors' : 'Units'}</label>
																			<input type="Number" className="form-control" placeholder={divisionArray[index].NoOfFloors} onChange={(e) => {
																				let array = divisionArray
																				array[index].NoOfFloors = e.target.value
																				console.log(array)
																				setDivisionArray(array)
																			}} />
																		</div>
																	</div>

																</>
															})
														}



													</div>
												</div>
											</div>
										</div>

                    <div
                      className={`tab-pane  ${
                        currentStep === "Finish" && `show active`
                      }`}
                      id="finish"
                    >
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-6">
                          <div className="text-center">
                            <i className="ph-duotone ph-gift f-50 text-danger"></i>
                            <h3 className="mt-4 mb-3">Congratulations</h3>
                            <div className="mb-3">
                              <div className="form-check d-inline-block">
                                <label
                                  className="form-check-label"
                                  for="customCheck1"
                                >
                                  We have shared a welcome message and a login
                                  credentials on their email
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex wizard justify-content-between flex-wrap gap-2 mt-3">
                      <div className="first">
                        {/* <a href="javascript:void(0);" className="btn btn-secondary"> First </a> */}
                      </div>
                      <div className="d-flex">
                        {currentStep === "User Information" && (
                          <div className="previous me-2">
                            <a
                              href="javascript:void(0);"
                              className="btn btn-secondary"
                              onClick={handleBackToPrevious}
                            >
                              {" "}
                              Back To Previous{" "}
                            </a>
                          </div>
                        )}
                        {currentStep === "Company Information" && (
                          <div className="previous me-2">
                            <a
                              href="javascript:void(0);"
                              className="btn btn-secondary"
                              onClick={handleBackToPrevious}
                            >
                              {" "}
                              Back To Previous{" "}
                            </a>
                          </div>
                        )}
                        {currentStep === "KYC" && (
                          <div className="previous me-2">
                            <a
                              href="javascript:void(0);"
                              className="btn btn-secondary"
                              onClick={handleBackToPrevious}
                            >
                              {" "}
                              Back To Previous{" "}
                            </a>
                          </div>
                        )}
                        {currentStep === "Facility" && (
                          <div className="previous me-2">
                            <a
                              href="javascript:void(0);"
                              className="btn btn-secondary"
                              onClick={handleBackToPrevious}
                            >
                              {" "}
                              Back To Previous{" "}
                            </a>
                          </div>
                        )}
                        {currentStep === "Finish" && (
                          <div className="previous me-2">
                            <a
                              href="javascript:void(0);"
                              className="btn btn-secondary"
                              onClick={handleBackToPrevious}
                            >
                              {" "}
                              Back To Previous{" "}
                            </a>
                          </div>
                        )}
                        {currentStep !== "Finish" && (
                          <div className="next">
                            <a
                              href="javascript:void(0);"
                              className="btn btn-secondary"
                              onClick={handleNextStep}
                            >
                              {" "}
                              Next Step{" "}
                            </a>
                          </div>
                        )}

                        {currentStep === "Finish" && (
                          <div className="last">
                            <a
                              href="javascript:void(0);"
                              className="btn btn-secondary"
                              onClick={handleFinish}
                            >
                              {" "}
                              Finish{" "}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        header="User Exists"
        visible={existingUser}
        style={{ width: "30vw" }}
        onHide={() => {
          visibleExistingUser(false);
        }}
      >
        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="card">
              <div className="card-body text-center">
                <i
                  className="ph-duotone ph-user-circle"
                  style={{ fontSize: 100 }}
                ></i>
                <br />
                <p>Fullname: {userExist.fullName}</p>

                <p>Email: {userExist.email}</p>
                <p>Phone Number: {userExist.phoneNumber}</p>
                <p>ID / Passport Number: {userExist.idNumber}</p>
                <p style={{ color: "green" }}>Use existing user:</p>
                <button
                  className="btn btn-primary btn-block"
                  onClick={() => {
                    if (useType === "Individual") {
                      setCurrentStep("KYC");
                      setProgressBar(900);
                    } else if (useType === "Company") {
                      setCurrentStep("Company Information");
                      setProgressBar(800);
                    }

                    visibleExistingUser(false);
                  }}
                >
                  Yes
                </button>
                <button
                  className="btn btn-danger btn-block"
                  style={{ marginLeft: 2 }}
                  onClick={() => {
                    visibleExistingUser(false);
                  }}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Layout>
  );
}

export default AddCompany;
